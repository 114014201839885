import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'

function IndexPage({ pageContext }) {
  const { ap } = pageContext
  return (
    <Layout>
    <div>
      These are all the pokemon:
      <ol>
        {ap.map(p => (
          <li><Link to={`/pokemon/${p}/`}>{p}</Link></li>
        ))}
      </ol>
    </div>
    </Layout>
  )
}

export default IndexPage
